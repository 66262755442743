"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _i18n = require("@/assets/js/i18n");
//
//
//
//
//
//
//
//
var _default = {
  props: {
    counting: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: "获取验证码"
    }
  },
  data() {
    return {
      countDown: 5,
      timer: null
    };
  },
  methods: {
    translateTitle: _i18n.translateTitle,
    onClick() {
      if (!this.counting) {
        this.$emit("count");
      }
    }
  },
  watch: {
    counting(val) {
      if (val) {
        this.countDown = 60;
        this.timer = setInterval(() => {
          if (this.countDown == 0) {
            clearInterval(this.timer);
            this.$emit("update:counting", false);
            return;
          }
          this.countDown--;
        }, 1000);
      } else {
        this.countDown = 60;
        clearInterval(this.timer);
      }
    }
  }
};
exports.default = _default;