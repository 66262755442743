"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _i18n = require("@/assets/js/i18n");
var _Code = _interopRequireDefault(require("./components/Code"));
var _InputNumber = _interopRequireDefault(require("@/components/common/InputNumber"));
var _message = require("@/components/dialog/message");
var _qrcode = _interopRequireDefault(require("qrcode.vue"));
var _account = require("@/model/user/account");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data() {
    const accountInfo = this.$route.query.accountInfo;
    // if (!accountInfo) {
    //   this.$router.push({
    //     path: "/login",
    //   });
    // }
    return {
      counting: false,
      secret: "",
      appDownload: [{
        img: require("./../../assets/img/Apple-store.png"),
        apk: require("./../../assets/img/Apple-qrCode.png"),
        name: "Apple"
      }, {
        img: require("./../../assets/img/Android-market.png"),
        apk: require("./../../assets/img/Android-qrCode.png"),
        name: "Android"
      }, {
        img: require("./../../assets/img/Google-play.png"),
        apk: require("./../../assets/img/Google-qrCode.png"),
        name: "Google"
      }],
      loginLoading: false,
      // 登录loading
      currentType: "0",
      // 当前选项
      verifyData: {
        // 登录数据
        code: "",
        email: "",
        google: ""
      },
      formRules: {
        code: [{
          required: true,
          message: this.translateTitle("请输入邮箱验证码"),
          trigger: "blur"
        }, {
          pattern: /^\d{6}$/,
          message: this.translateTitle(`验证码应为 6 位数字`),
          trigger: "blur"
        }],
        email: [{
          required: true,
          message: this.translateTitle(`请输入邮箱`),
          trigger: "blur"
        }, {
          pattern: /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/,
          message: this.translateTitle(`邮箱格式不正确`),
          trigger: "blur"
        }],
        google: [{
          required: true,
          message: this.translateTitle(`请输入谷歌验证码`),
          trigger: "blur"
        }, {
          pattern: /^\d{6}$/,
          message: this.translateTitle(`验证码应为 6 位数字`),
          trigger: "blur"
        }]
      },
      accountInfo
    };
  },
  mounted() {
    this.getGoogleSecret();
  },
  computed: {
    googleCode() {
      if (this.accountInfo) {
        return `otpauth://totp/${this.accountInfo.username})?issuer=Topcredit&secret=${this.secret}`;
      } else {
        return "";
      }
    }
  },
  methods: {
    translateTitle: _i18n.translateTitle,
    onPaste(e) {
      var data = null;
      var clipboardData = e.clipboardData; // IE
      if (!clipboardData) {
        //chrome
        clipboardData = e.originalEvent.clipboardData;
      }
      data = clipboardData.getData("Text");
      this.verifyData.google = data;
    },
    onSendCode() {
      if (!this.verifyData.email) {
        (0, _message.showMessage)({
          message: this.translateTitle(`请输入邮箱`)
        });
        return;
      }
      const data = {
        email: this.verifyData.email
      };
      (0, _account.sendEmailCode)(data).then(rs => {
        if (rs.status == 200) {
          (0, _message.showMessage)({
            message: this.translateTitle(`发送成功`),
            type: "success"
          });
          this.counting = true;
        }
      });
    },
    // 复制成功
    copySuccess() {
      (0, _message.showMessage)({
        message: this.translateTitle(`内容复制成功`),
        type: "success"
      });
    },
    // 复制失败
    copyError() {
      (0, _message.showMessage)({
        message: this.translateTitle(`内容复制失败`)
      });
    },
    // 获取谷歌验证
    getGoogleSecret() {
      (0, _account.getGoogleSecretFirst)().then(res => {
        if (res.status === 200) {
          this.secret = res.data.secret;
        } else {
          // showMessage({ message: res.msg });
        }
      });
    },
    onVerify() {
      this.$refs.verifyForm.validate(valid => {
        if (valid) {
          this.loginLoading = true;
          if (this.currentType == 0) {
            const data = {
              google_secret: this.secret,
              google_code: this.verifyData.google
            };
            (0, _account.bindGoogleAuthApplication)(data).then(rs => {
              if (rs.status == 200) {
                this.handleSuccess(rs.data);
                return;
              }
              this.loginLoading = false;
            });
          } else {
            const data = {
              email: this.verifyData.email,
              email_code: this.verifyData.code
            };
            (0, _account.bindEmailFrist)(data).then(rs => {
              if (rs.status == 200) {
                this.handleSuccess(rs.data);
                return;
              }
              this.loginLoading = false;
            });
          }
        }
      });
    },
    async handleSuccess(data) {
      (0, _message.showMessage)({
        message: this.translateTitle(`登录成功`),
        type: "success"
      });
      //写入cookie, 邮箱登录 , 手机登录  注册一致
      location.href = "/";
      // this.$store.dispatch("getUserInfo").then((res) => {
      //   let jump = this.$route.params.jump;
      //   if (jump) {
      //     location.href = jump;
      //     return;
      //   } else {
      //   }
      // });
    },

    onTabChange() {
      this.$refs.verifyForm.resetFields();
    }
  },
  components: {
    InputNumber: _InputNumber.default,
    Code: _Code.default,
    QrcodeVue: _qrcode.default
  }
};
exports.default = _default;